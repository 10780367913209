import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import * as firebase from "firebase";
import { StatemanagementService } from "./_services/statemanagement.service";
import { FirebaseCollection } from "./_utils/firebaseCollection.constant";

@Injectable({
  providedIn: "root",
})
export class RetroService {
  public id;
  public retroCategories: AngularFirestoreCollection<any>;

  constructor(
    public firestore: AngularFirestore,
    public _statemanagementService: StatemanagementService
  ) {
    this.id = _statemanagementService.teamCode;
    this.retroCategories = firestore.collection(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/retroCategories`
    );
  }

  getCategories() {
    return this.retroCategories.valueChanges();
  }

  async addCategory(categoryName: string): Promise<any> {
    const newCategoryRef: firebase.firestore.DocumentReference =
      await this.retroCategories.add({});

    return newCategoryRef.update({
      name: categoryName,
      id: newCategoryRef.id,
    });
  }

  async removeCategory(categoryId: number): Promise<any> {
    return this.firestore
      .doc(
        `/${FirebaseCollection.TEAM_CODE}/${this.id}/retroCategories/${categoryId}`
      )
      .delete();
  }

  async addCategoryCard(categoryId: string, text: string): Promise<any> {
    const categoryRef = this.firestore.doc(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/retroCategories/${categoryId}`
    );

    // Create a timestamp to order the cards, and use below in the 'cards' object on the category document
    let orderId = new Date().toUTCString();

    return categoryRef.update({
      [`cards.${orderId}`]: { id: orderId, value: text, show: false },
    });
  }

  async toggleCategoryCard(
    categoryId: string,
    cardId: string,
    value: boolean
  ): Promise<any> {
    const categoryRef = this.firestore.doc(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/retroCategories/${categoryId}`
    );

    return categoryRef.update({
      [`cards.${cardId}.show`]: value,
    });
  }
}
