import { NgxUiLoaderModule } from "ngx-ui-loader";
import { TeamCode } from "./team/team.page";
import { environment } from "./../environments/environment.prod";
import { RetroCardPageModule } from "./retro-card/retro-card.module";
import { RetroCardPage } from "./retro-card/retro-card.page";
import { NavigationComponent } from "./navigation/navigation.component";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { DetailPageModule } from "./detail/detail.module";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
@NgModule({
  declarations: [AppComponent, NavigationComponent, TeamCode],
  entryComponents: [NavigationComponent, RetroCardPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    DetailPageModule,
    HttpClientModule,
    RetroCardPageModule,
    NgxUiLoaderModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
