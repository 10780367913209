import { map } from "rxjs/operators";
import { TeamCodeService } from "./../_services/team.service";
import { StatemanagementService } from "./../_services/statemanagement.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { TeamCode } from "../_models/teamCode.model";
import { Status } from "../_utils/status.enum";

@Injectable({
  providedIn: "root",
})
export class HomeGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let teamCode = next.paramMap.get("id");
    if (teamCode) {
      return this._teamCodeService.getTeamCode(teamCode).pipe(
        map((teamSnap) => {
          if (teamSnap && teamSnap.status === Status.ACTIVE) {
            this._statemanagementService.teamCode = teamCode;
            this._statemanagementService.teamCodeData = teamSnap as TeamCode;
            return true;
          } else {
            this._router.navigate(["/"]);
            return false;
          }
        })
      );
    } else {
      this._router.navigate(["/"]);
      return false;
    }
  }
  constructor(
    private _statemanagementService: StatemanagementService,
    private _teamCodeService: TeamCodeService,
    private _router: Router
  ) {}
}
