import { Component, OnInit, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { RetroService } from '../retro.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-retro-card',
  templateUrl: './retro-card.page.html',
  styleUrls: ['./retro-card.page.scss'],
})
export class RetroCardPage implements OnInit {
  category: any;
  name: any
  private form: FormGroup;

  // This is the category for which a user is submitting a card
  @Input() option: any;

  constructor(
    public modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    public loadingController: LoadingController,
    public retroProvider: RetroService)
    { }

  ngOnInit() {
    this.category = this.option;
    console.log('Category: ', this.category);
    this.name = this.option.name
    this.prepareForm();
  }

  /**
   * Prepare the  form.
   */
  private prepareForm(): void {
    this.form = this.formBuilder.group({
      text: ['', Validators.compose([Validators.required])]
    });
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

  async onSubmit() {
    let categoryId = this.category.id;
    let cardText = this.form.value.text
    const loading = await this.loadingController.create();

    loading.present();

    this.retroProvider.addCategoryCard(categoryId, cardText).then(() => {
      this.dismiss();
      loading.dismiss();
    }).catch((error) => {
      console.log('Error adding card: ', error);
      this.dismiss();
      loading.dismiss();
    })
  }

}
