import { StatemanagementService } from "./_services/statemanagement.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { firestore } from "firebase";
import { FirebaseCollection } from "./_utils/firebaseCollection.constant";

@Injectable({
  providedIn: "root",
})
export class PlayersService {
  public id;
  public userId: string;
  public players: AngularFirestoreCollection<any>;
  public player: AngularFirestoreDocument<any>;
  public playersBehaviorSubject = new BehaviorSubject<any[]>(null);

  constructor(
    public afAuth: AngularFireAuth,
    public _statemanagementService: StatemanagementService,
    public firestore: AngularFirestore
  ) {
    this.id = _statemanagementService.teamCode;
    this.players = firestore.collection(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/players`
    );
  }

  getUser(): firebase.User {
    return this.afAuth.auth.currentUser;
  }

  anonymousLogin(): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth.signInAnonymously();
  }

  resetPlayers() {}

  getPlayers() {
    return this.players.valueChanges();
  }

  async addPlayer(playerName: string): Promise<any> {
    const newPlayerRef: firebase.firestore.DocumentReference =
      await this.players.add({});

    return newPlayerRef.update({
      name: playerName,
      id: newPlayerRef.id,
      submitted: false,
    });
  }

  async addPlayerCard(playerId: number, imgSrc: string): Promise<any> {
    const playerRef = this.firestore.doc(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/players/${playerId}`
    );

    return playerRef.update({
      imgSrc: imgSrc,
      submitted: true,
    });
  }

  async reset() {
    const playersCollection = this.firestore.collection(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/players`
    );

    playersCollection.get().forEach((item) => {
      return item.docs.map((m) => {
        return this.firestore
          .doc(`/${FirebaseCollection.TEAM_CODE}/${this.id}/players/${m.id}`)
          .update({ submitted: false });
      });
    });
  }

  async reveal(revealValue: boolean) {
    const playersCollection = this.firestore.collection(
      `/${FirebaseCollection.TEAM_CODE}/${this.id}/players`
    );

    playersCollection.get().forEach((item) => {
      return item.docs.map((m) => {
        return this.firestore
          .doc(`/${FirebaseCollection.TEAM_CODE}/${this.id}/players/${m.id}`)
          .update({ reveal: revealValue });
      });
    });
  }

  async removePlayer(playerId: number): Promise<any> {
    return this.firestore
      .doc(`/${FirebaseCollection.TEAM_CODE}/${this.id}/players/${playerId}`)
      .delete();
  }
}
