import { Injectable } from "@angular/core";
import { TeamCode } from "../_models/teamCode.model";

@Injectable({
  providedIn: "root",
})
export class StatemanagementService {
  public teamCode = "";
  public teamCodeData = {} as TeamCode;
  constructor() {}
}
