import { FirebaseCollection } from "../_utils/firebaseCollection.constant";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { map } from "rxjs/operators";
import { Status } from "../_utils/status.enum";
import { Observable } from "rxjs";
import { TeamCode } from "../_models/teamCode.model";

@Injectable({
  providedIn: "root",
})
export class TeamCodeService {
  createTeamCode(data: any) {
    return this._angularFireFunctions
      .httpsCallable("createTeamCode")(data)
      .pipe(
        map((response) => {
          if (response.status === Status.SUCCESS) {
            return response.data;
          } else {
            throw Error(response.message);
          }
        })
      );
  }

  getTeamCode(teamCode: string): Observable<TeamCode | null> {
    return this._angularFirestore
      .collection(FirebaseCollection.TEAM_CODE)
      .doc(teamCode)
      .get()
      .pipe(
        map((_teamCodeSnap) => {
          if (_teamCodeSnap.exists) {
            return new TeamCode({
              $key: _teamCodeSnap.id,
              ...(_teamCodeSnap.data() as any),
            });
          } else {
            return null;
          }
        })
      );
  }
  constructor(
    private _angularFireFunctions: AngularFireFunctions,
    private _angularFirestore: AngularFirestore
  ) {}
}
