import { StatemanagementService } from "./../_services/statemanagement.service";
import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  constructor(
    public popoverController: PopoverController,
    public _statemanagementService: StatemanagementService
  ) {}

  ngOnInit() {}

  dismiss() {
    this.popoverController.dismiss();
  }
}
