// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAYkw5he-2uCfJ2itfYpv0SoTRClEq2m64",
    authDomain: "planningpoker-1234.firebaseapp.com",
    databaseURL: "https://planningpoker-1234.firebaseio.com",
    projectId: "planningpoker-1234",
    storageBucket: "",
    messagingSenderId: "850683396760",
    appId: "1:850683396760:web:755025f7bb7d9fe35c46ed"
  },
  stripePublishableKey: 'pk_live_51JocNYDwN3eo6rrakTUvQBNT7ASIDCnYsHuGKktDpa5lQXQkdRAusGuR0Egd1fTmk1Lt3aCn8m6NTnS1kEbQGT9a00irtk4uqb'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
