import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PokerOptionsService {

  constructor() { }

  getOptions() {
    return [
      {
        option: "1",
        imgSrc: "../assets/images/1.svg"
      },
      {
        option: "2",
        imgSrc: "../assets/images/2.svg"
      },
      {
        option: "3",
        imgSrc: "../assets/images/3.svg"
      },
      {
        option: "5",
        imgSrc: "../assets/images/5.svg"
      },
      {
        option: "8",
        imgSrc: "../assets/images/8.svg"
      },
      {
        option: "13",
        imgSrc: "../assets/images/13.svg"
      },
      {
        option: "20",
        imgSrc: "../assets/images/20.svg"
      },
      {
        option: "40",
        imgSrc: "../assets/images/40.svg"
      },
      {
        option: "100",
        imgSrc: "../assets/images/100.svg"
      },
      {
        option: "Dragons",
        imgSrc: "../assets/images/dragons.svg"
      },
      {
        option: "Infinity",
        imgSrc: "../assets/images/infinity.svg"
      },
      {
        option: "Ping Pong",
        imgSrc: "../assets/images/pingpong.svg"
      }
    ]
  }
}
