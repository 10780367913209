export class TeamCode {
  createdDate: Date;
  expiredDate: Date;
  paymentIntendId: string;
  sessionId: string;
  status: string;
  constructor(teamCodeData: any) {
    this.createdDate = teamCodeData.createdDate.toDate();
    this.expiredDate = teamCodeData.expiredDate.toDate();
    this.paymentIntendId = teamCodeData.paymentIntendId;
    this.sessionId = teamCodeData.sessionId;
    this.status = teamCodeData.status;
  }
}
