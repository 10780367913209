import { Component } from "@angular/core";

@Component({
  selector: "app-team",
  templateUrl: "team.page.html",
  styleUrls: ["team.page.scss"],
})
export class TeamCode {
  constructor() {}

  ngOnInit() {}
}
