import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlayersService } from '../players.service';
import { PokerOptionsService } from '../poker-options.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {
  pokerOptions = [];
  player: any;
  selectedCard: any;
  selected: boolean = false;
  selectedIdx: number;

  // "value" passed in componentProps (Player Object)
  @Input() option: any;

  constructor(
    public modalCtrl: ModalController,
    public playersProvider: PlayersService,
    public optionsProvider: PokerOptionsService)
    { }

  ngOnInit() {
    this.player = this.option;
    this.pokerOptions = this.optionsProvider.getOptions();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

  cardSubmit() {
    console.log('Submitted: ', this.selectedCard, ' For Developer: ', this.player.name);
    this.playersProvider.addPlayerCard(this.player.id, this.selectedCard.imgSrc).then(() => {
      this.dismiss();
    })
  }

  cardClick(card, i) {
    this.selectedIdx = i;
    this.selected = true;
    this.selectedCard = card;
    console.log('Card Selected: ', card);
  }

}
