import { HomeGuard } from "./_guards/home.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TeamCode } from "./team/team.page";

const routes: Routes = [
  { path: "", redirectTo: "landing", pathMatch: "full" },
  {
    path: "landing",
    loadChildren: "./landing/landing.module#LandingPageModule",
  },
  {
    path: "team/:id",
    canActivate: [HomeGuard],
    component: TeamCode,
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        loadChildren: "./home/home.module#HomePageModule",
      },
      {
        path: "detail/:option",
        loadChildren: "./detail/detail.module#DetailPageModule",
      },

      { path: "retro", loadChildren: "./retro/retro.module#RetroPageModule" },
      {
        path: "retro-card",
        loadChildren: "./retro-card/retro-card.module#RetroCardPageModule",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
