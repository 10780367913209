export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAYkw5he-2uCfJ2itfYpv0SoTRClEq2m64",
    authDomain: "planningpoker-1234.firebaseapp.com",
    databaseURL: "https://planningpoker-1234.firebaseio.com",
    projectId: "planningpoker-1234",
    storageBucket: "",
    messagingSenderId: "850683396760",
    appId: "1:850683396760:web:755025f7bb7d9fe35c46ed"
  },
  stripePublishableKey: 'pk_live_51JocNYDwN3eo6rrakTUvQBNT7ASIDCnYsHuGKktDpa5lQXQkdRAusGuR0Egd1fTmk1Lt3aCn8m6NTnS1kEbQGT9a00irtk4uqb'
};
